const data = {
	name: 'US East (N. Virginia)',
	icon: 'Arabic',
	versions: [
		{
			name: 'Review later in cart',
			applications: [
				{
					id: 'laravel',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-hosting-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-hosting-professional',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/laravel-hosting-enterprise',
						},
					],
				},
				{
					id: 'php',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-startup-virginia',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-professional-virginia',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/php-hosting-enterprise-virginia',
						},
					],
				},
				{
					id: 'nginx',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-startup-virginia',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-professional-virginia',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/nginx-hosting-enterprise-virginia',
						},
					],
				},
				{
					id: 'django',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-startup-virginia',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-rocket-virginia',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-professional-virginia',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/django-hosting-enterprise-virginia',
						},
					],
				},
				{
					id: 'jupyter',
					pricings: [				
						{
							id: 'startup',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook',
						},
						{
							id: 'rocket',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-rocket',
						},
						{
							id: 'professional',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-professional',
						},
						{
							id: 'enterprise',
							link:
								'https://cloud.hostjane.com/hosting/store/aws/jupyter-notebook-hosting-enterprise',
						},
					],
				},
			],
		},
	],
};

export default data;